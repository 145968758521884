<template>
  <form class="row text-left">
    <div class="col col-12 col-md-6 p-4" id="calculator-slider-bar">

      <div class="form-group mt-4">
        <label for="project-cnt">Wie viele Projekte betreuen Sie derzeit?</label>
        <div class="d-flex align-items-center">
	        <input type="range" class="custom-range" min="1" max="100" v-model="projectCnt">
	        <input type="number" class="form-control ml-2" min="1" id="project-cnt" v-model="projectCnt" style="max-width: 80px;">
        </div>
      </div>

      <div class="form-group">
        <label for="software-spent">Was zahlen Sie derzeit monatlich für Mängelmanagement-Software?</label>
        <div class="d-flex align-items-center">
	        <input type="range" class="custom-range" min="0" max="10000" v-model="softwareSpent">
        	<input type="number" class="form-control ml-2" min="0" id="software-spent" v-model="softwareSpent" style="max-width: 80px;">
        </div>
      </div>

      <div class="form-group">
        <label>Welche Methode verwenden Sie derzeit?</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="method" id="manually" value="0.33" v-model="method">
          <label class="form-check-label" for="manually" style="font-weight: 400;">Manuell (Excel, Emails, Telefon, ...)</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="method" id="software" value="0.67" v-model="method">
          <label class="form-check-label" for="software" style="font-weight: 400;">Andere Software</label>
        </div>
      </div>

      <div class="form-group">
        <label for="time-spent">Wie viele Stunden verbringen Sie wöchentlich mit Mangelmanagement?</label>
        <div class="d-flex align-items-center">
	        <input type="range" class="custom-range" min="1" max="160" v-model="timeSpent">
        	<input type="number" class="form-control ml-2" min="1" id="time-spent" v-model="timeSpent" style="max-width: 80px;">
        </div>
      </div>

      <div class="form-group">
        <label for="salary">Was ist Ihre Arbeitsstunde wert?</label>
        <div class="d-flex align-items-center">
	        <input type="range" class="custom-range" min="30" max="300" v-model="salary">
        	<input type="text" class="form-control ml-2" min="0" id="salary" v-model="salary" style="max-width: 80px;">
        </div>
      </div>
    </div>
    <div class="col col-12 col-md-6 p-4 text-center" id="calculator-results">
      <div class="row">
        <div class="col col-12">
        	<p class="text-h3 mt-4 mb-2" style="opacity: .5;">Optimiert, um Zeit, Geld und Nerven zu sparen</p>
        	<div class="text-h1 mt-0 mb-5">Insgesamt sparen Sie mit Mangelpilot <span :style="{ color: totalSaved >= 0 ? 'green' : 'black' }"><span>{{totalSaved.toFixed(0)}}</span>€ im Monat</span>.*</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
    	  <radial-progress-bar :diameter="120" :completed-steps="timeToSpend / timeSpent" :total-steps="1" startColor="green" stopColor="green" innerStrokeColor="#f5f5f5" :animateSpeed="100" class="mx-auto">
	            <div class="text-h2 m-0 strike">{{timeSpent}} Std</div>
	            <div class="text-h2 m-0">{{timeToSpend.toFixed(0)}} Std</div>
              <span class="text-grey-9">wöchentlich</span>
		  </radial-progress-bar>
          <div>Sie sparen wöchentlich <br><strong>{{timeSaved.toFixed(0)}} Arbeitsstunden</strong>.</div>
        </div>
        <div class="col">
    	  <radial-progress-bar :diameter="120" :completed-steps="softwareToSpend / softwareSpent" :total-steps="1" :startColor="softwareSpent > softwareToSpend ? 'green' : 'transparent'" :stopColor="softwareSpent > softwareToSpend ? 'green' : 'transparent'" innerStrokeColor="#f5f5f5" :animateSpeed="100" class="mx-auto">
	            <div class="text-h2 m-0 strike" :style="{ color: softwareSpent > softwareToSpend ? 'red' : 'black' }">{{softwareSpent}}€</div>
	            <div class="text-h2 m-0" :style="{ color: softwareSpent > softwareToSpend ? 'black' : 'red' }">{{softwareToSpend}}€</div>
              <span class="text-grey-9">monatlich</span>
		  </radial-progress-bar>
          <div>Sie sparen monatlich <br><strong>{{softwareSaved.toFixed(0)}}€</strong> für Software.</div>
        </div>
        <div class="col">
    	  <radial-progress-bar :diameter="120" :completed-steps="salaryToSpend / salarySpent" :total-steps="1" startColor="green" stopColor="green" innerStrokeColor="#f5f5f5" :animateSpeed="100" class="mx-auto">
	            <div class="text-h2 m-0 strike">{{salarySpent}}€</div>
	            <div class="text-h2 m-0">{{salaryToSpend.toFixed(0)}}€</div>
              <span class="text-grey-9">monatlich</span>
		  </radial-progress-bar>
          <div>Sie sparen monatlich <br><strong>{{salarySaved.toFixed(0)}}€</strong> Lohn.</div>
        </div>
      </div>
    </div>
    <div class="col col-12 text-center px-4" style="order: 3">
      <div>* Die Schätzungen basieren auf Tests und Kundenumfragen und sind nicht rechtlich bindend.</div>
      <div>** Die Kosten basieren auf folgenden Abonnements: <strong v-if="cntXl">{{cntXl}}x XL</strong> <strong v-if="cntLg">{{cntLg}}x LG</strong> <strong v-if="cntMd">{{cntMd}}x MD</strong> <strong v-if="cntSm">{{cntSm}}x SM</strong></div>
    </div>
  </form>
</template>

<script>
	import RadialProgressBar from 'vue-radial-progress'

	export default {
  		name: 'Calculator',
  		components: {
			RadialProgressBar
		},
		data: function () {
			return {
				timeSpent: 8,
				method: 0.67,
				projectCnt: 3,
				softwareSpent: 500,
				salary: 80,
				SUBSCRIPTIONS: {
					xl: {
						projectCnt: 20,
						price: 500
					},
					lg: {
						projectCnt: 10,
						price: 300
					},
					md: {
						projectCnt: 5,
						price: 200
					},
					sm: {
						projectCnt: 1,
						price: 50
					}
				}
			}
		},
		computed: {
			timeToSpend: function () {
				return this.timeSpent * this.method
			},
			timeSaved: function () {
				return this.timeSpent - this.timeToSpend
			},
			softwareToSpend: function () {
				return this.bestVariant.price
			},
			softwareSaved: function () {
				return this.softwareSpent - this.softwareToSpend
			},
			cntXl: function () {
				return this.bestVariant.cnts.xl
			},
			cntLg: function () {
				return this.bestVariant.cnts.lg
			},
			cntMd: function () {
				return this.bestVariant.cnts.md
			},
			cntSm: function () {
				return this.bestVariant.cnts.sm
			},
			bestVariant: function () {
				return [
					this.calculateSubscriptionVariant('xl', this.projectCnt),
					this.calculateSubscriptionVariant('lg', this.projectCnt),
					this.calculateSubscriptionVariant('md', this.projectCnt),
					this.calculateSubscriptionVariant('sm', this.projectCnt)
				].sort((a, b) => a.price - b.price || a.projectCnt - b.projectCnt)[0]
			},
			salaryToSpend: function () {
				return this.salary * this.timeToSpend
			},
			salarySpent: function () {
				return this.salary * this.timeSpent
			},
			salarySaved: function () {
				return this.salarySpent - this.salaryToSpend
			},
			totalSaved: function () {
				return this.salarySaved + this.softwareSaved
			}
		},
		methods: {
			calculateSubscriptionVariant: function (size, projectCnt) {
				let variant = {
					price: 0,
					projectCnt: 0,
					cnts: {
						xl: 0,
						lg: 0,
						md: 0,
						sm: 0
					}
				}

				// calculate xl
				variant.cnts.xl = Math.ceil(projectCnt / this.SUBSCRIPTIONS.xl.projectCnt)
				if (size === 'xl' || projectCnt%this.SUBSCRIPTIONS.xl.projectCnt === 0) {
					variant.price = variant.cnts.xl * this.SUBSCRIPTIONS.xl.price
					variant.projectCnt = variant.cnts.xl * this.SUBSCRIPTIONS.xl.projectCnt
					return variant
				}

				variant.cnts.xl = variant.cnts.xl - 1
				projectCnt = projectCnt%this.SUBSCRIPTIONS.xl.projectCnt

				// calculate lg
				variant.cnts.lg = Math.ceil(projectCnt / this.SUBSCRIPTIONS.lg.projectCnt)
				if (size === 'lg' || projectCnt%this.SUBSCRIPTIONS.lg.projectCnt === 0) {
					variant.price = variant.cnts.xl * this.SUBSCRIPTIONS.xl.price + variant.cnts.lg * this.SUBSCRIPTIONS.lg.price
					variant.projectCnt = variant.cnts.xl * this.SUBSCRIPTIONS.xl.projectCnt + variant.cnts.lg * this.SUBSCRIPTIONS.lg.projectCnt
					return variant
				}

				variant.cnts.lg = variant.cnts.lg - 1
				projectCnt = projectCnt%this.SUBSCRIPTIONS.lg.projectCnt

				// calculate md
				variant.cnts.md = Math.ceil(projectCnt / this.SUBSCRIPTIONS.md.projectCnt)
				if (size === 'md' || projectCnt%this.SUBSCRIPTIONS.md.projectCnt === 0) {
					variant.price = variant.cnts.xl * this.SUBSCRIPTIONS.xl.price + variant.cnts.lg * this.SUBSCRIPTIONS.lg.price + variant.cnts.md * this.SUBSCRIPTIONS.md.price
					variant.projectCnt = variant.cnts.xl * this.SUBSCRIPTIONS.xl.projectCnt + variant.cnts.lg * this.SUBSCRIPTIONS.lg.projectCnt + variant.cnts.md * this.SUBSCRIPTIONS.md.projectCnt
					return variant
				}

				variant.cnts.md = Math.max(0, variant.cnts.md - 1)
				projectCnt = projectCnt%this.SUBSCRIPTIONS.md.projectCnt

				// calculate sm
				variant.cnts.sm = Math.ceil(projectCnt / this.SUBSCRIPTIONS.sm.projectCnt)
				variant.price = variant.cnts.xl * this.SUBSCRIPTIONS.xl.price + variant.cnts.lg * this.SUBSCRIPTIONS.lg.price + variant.cnts.md * this.SUBSCRIPTIONS.md.price + variant.cnts.sm * this.SUBSCRIPTIONS.sm.price
				variant.projectCnt = variant.cnts.xl * this.SUBSCRIPTIONS.xl.projectCnt + variant.cnts.lg * this.SUBSCRIPTIONS.lg.projectCnt + variant.cnts.md * this.SUBSCRIPTIONS.md.projectCnt + variant.cnts.sm * this.SUBSCRIPTIONS.sm.projectCnt
				return variant
			}
		}
	}
</script>

<style lang="scss">
	@media(max-width: 767px) {
		#calculator {
			#calculator-slider-bar {
				order: 2;
			}
			#calculator-results {
				order: 1;
			}
		}
	}
</style>