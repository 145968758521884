require('./bootstrap');

require('alpinejs');

import Vue from 'vue'

Vue.component('calculator', require('./components/Calculator.vue').default)

const app = new Vue({
	el: '#calculator'
})
